import React, { useEffect, useState } from "react";
import { AutoComplete } from 'primereact/autocomplete';
import fetch from '../../../config/service';
import config from "../../../config/config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAsterisk } from "@fortawesome/free-solid-svg-icons";

const DoAutoCompleteField = (props) => {
    const [filteredSuggestions, setFilteredSuggestions] = useState([]);
    const [isValidate,setIsValidate] = useState(false);
    const [noData,setNoData] = useState(false);
    const [errorMessage,setErrorMessage] = useState('');
    const id = props.id ? props.id : props.name;
    const label = props.label ? props.label : props.name;
    const name = props.name;
    const type = props.type ? props.type : 'text';
    let field = props.field;
    const placeholder = props.placeholder;
    const item = props.item ? props.item : {};

    useEffect(() => {
        getAllSuggestions();
    }, [])

    const onSelectRecord = (e) => {
        setNoData(false);
        setIsValidate(false);
        setErrorMessage('');
        if (props.input) {
            const { name, onChange } = this.props.input;
            onChange(e.value);
            this.props.handleAutoCompleteData(e.value, name)
            if (e && e.value) {
                this.removeDuplicates(e.value, name);
            }
        }

    }

    // to remove duplicates
    const removeDuplicates = () => {
        if (this.state.filteredSuggestions) {
            setSuggestions(this.state.filteredSuggestions);
        }
    }

    const getAllSuggestions = (event, type) => {
        let url;
        // this.setState({
        //     isValidate: false,
        //     noData: false,
        //     errorMessage: ''
        // });
        setIsValidate(false);
        setNoData(false);
        setErrorMessage('');
        let filterCriteria = {
            limit: 30,
            page: 1,
            sortfield: "created",
            direction: "desc",
            criteria: []
        };

        if (props.item.hasDependencyField && props.getValues && props.getValues(props.item.hasDependencyField) && props.getValues(props.item.hasDependencyField)["_id"]) {
            filterCriteria['criteria'].push({
                "key": props.item.fieldForKey,
                "value": props.getValues(props.item.hasDependencyField)["_id"],
                "type": "eq"
            })
        }

        if (event && event.query) {
            if (item.searchPatternMatch && !item.searchPatternMatch?.test(event.query)) {//searchPatternMatch it should Regular expresetion
                return;
            }

            if (item?.searchGlobally) {
                filterCriteria["globalSearch"] = { "value": event.query, "type": "user" };
            } else {
                filterCriteria['criteria'].push({
                    "key": props.searchField,
                    "value": event.query,
                    "type": "regexOr"
                });
            }
        };

        if (item.hasDependencyField && props.getValues && props.getValues(props.item.hasDependencyField) && props.getValues(props.item.hasDependencyField)["_id"]) {
            let value;
            if (item.hasDependencyFieldType === 'in') {
                value = [props.getValues(props.item.hasDependencyField)["_id"]];
            } else {
                value = props.getValues(props.item.hasDependencyField)["_id"];
            }
            filterCriteria['criteria'].push({
                "key": props.item.fieldForKey,
                "value": value,
                "type": item.hasDependencyFieldType ? item.hasDependencyFieldType : 'eq',
            })
        }

        if (item.customCriteria && item.customCriteria.length > 0) {
            filterCriteria['criteria'] = [...filterCriteria['criteria'], ...item.customCriteria]
        }

        let apiUrl = props.searchApi;
        url = `${apiUrl}?searchFrom=autoComplete&filter=${JSON.stringify(filterCriteria)}`;
        return fetch('GET', url)
            .then((response) => {
                if (response) {
                    let dropdownData = response[apiUrl] || [];
                    if (dropdownData.length === 0) {
                        setNoData(true);
                    }
                    setFilteredSuggestions(dropdownData);
                }
            }).catch((err) => {
                return err;
            });
    }

    const setSuggestions = async (dropdownData) => {
        if (props.input && props.input.value) {
            if (props.input.value.length > 0) {
                let values = props.input.value;
                if (Array.isArray(values)) {
                    values.forEach(element => {
                        let field = '_id';
                        dropdownData = dropdownData.filter((item) => item[field] !== element[field]);
                    });
                }
            }
        }
        console.log(dropdownData);
        setFilteredSuggestions(dropdownData);
    }

    let markRequired = () => {
        return (
            <FontAwesomeIcon
                color='red'
                icon={faAsterisk}
                style={{ width: '7px', marginBottom: "5px", marginLeft: "2px" }}
            />
        );
    };

    return (
        <div className="flex flex-column">

            <div className="flex align-center"> {/* Added a wrapper div with flex class */}
                <label htmlFor={id} className="text-capitalize">{label}</label>
                {props.markReq && props.markReq === true && markRequired()}
            </div>
            <AutoComplete
                inputId={field.name}
                value={field.value}
                onChange={field.onChange}
                // inputRef={field.ref}
                suggestions={filteredSuggestions}
                completeMethod={getAllSuggestions}
                placeholder={placeholder}
                field={props.searchField}
                dropdown={true}
                multiple={props.multiple}
                selectionLimit={props.selectionLimit ? props.selectionLimit : config.selectionLimit}
                showEmptyMessage={true}
                emptyMessage='No Records'
            />

            <small className="text-danger ">{props.fieldState.invalid ? props.errors[props.name]?.message : ''}</small>
        </div>
    )

}

export default DoAutoCompleteField;