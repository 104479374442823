const commonSampleFilePath = "http://localhost:3000/images/sample";

const configSampleFiles = {
  employees:
    "http://api.hospitalnew1.dosystemsinc.com/images/sample_employee_bulk_upload_file.csv",
  Activitiess:
    "http://api.hospitalnew1.dosystemsinc.com/images/sample_Activities_bulk_upload_file.csv",
  "Email Templatess":
    "http://api.hospitalnew1.dosystemsinc.com/images/sample_Email Templates_bulk_upload_file.csv",
  Roless:
    "http://api.hospitalnew1.dosystemsinc.com/images/sample_Roles_bulk_upload_file.csv",
  "Upload Historys":
    "http://api.hospitalnew1.dosystemsinc.com/images/sample_Upload History_bulk_upload_file.csv",
  "Email Statuss":
    "http://api.hospitalnew1.dosystemsinc.com/images/sample_Email Status_bulk_upload_file.csv",
  Hospitalss:
    "http://api.hospitalnew1.dosystemsinc.com/images/sample_Hospitals_bulk_upload_file.csv",
  Patientss:
    "http://api.hospitalnew1.dosystemsinc.com/images/sample_Patients_bulk_upload_file.csv",
  Formss:
    "http://api.hospitalnew1.dosystemsinc.com/images/sample_Forms_bulk_upload_file.csv",
  Appointmentss:
    "http://api.hospitalnew1.dosystemsinc.com/images/sample_Appointments_bulk_upload_file.csv",
  Questionss:
    "http://api.hospitalnew1.dosystemsinc.com/images/sample_Questions_bulk_upload_file.csv",
};
export default configSampleFiles;
