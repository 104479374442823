import React,{useEffect} from "react";
import { MultiSelect } from 'primereact/multiselect';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAsterisk } from "@fortawesome/free-solid-svg-icons";

const DoMultiSelectField = (props) => {
  const id = props.id ? props.id : props.name;
  const label = props.label ? props.label : props.name;
  const optionLabel = props.optionLabel;
  const name = props.name;
  const options = props.options;
  const placeholder = props.placeholder;
  const maxSelectedLabels = props.maxSelectedLabels;
  let field = props.field;

  useEffect(() => {
    if(props.onChange){
        props.onChange(field.value)
    }
}, [field.value]);

  let markRequired = () => {
    return (
      <FontAwesomeIcon
        color='red'
        icon={faAsterisk}
        style={{ width: '7px', marginBottom: "5px", marginLeft: "2px" }}
      />
    );
  };

  return (
    <div className="flex flex-column">
      <div className="flex align-center"> {/* Added a wrapper div with flex class */}
        <label htmlFor={id} className="text-capitalize">{label}</label>
        {props.markReq && props.markReq === true && markRequired()}
      </div>
      <MultiSelect
        id={field.name}
        name={name}
        value={field.value}
        options={options}
        onChange={(e) => field.onChange(e.value)}
        optionLabel={optionLabel}
        placeholder={placeholder}
        maxSelectedLabels={maxSelectedLabels}
      />
      <small className="text-danger ">{props.fieldState.invalid ? props.errors[props.name]?.message : ''}</small>
    </div>
  )

}

export default DoMultiSelectField;