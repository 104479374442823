import React from 'react';
import PropTypes from 'prop-types';
import { Badge } from 'reactstrap';
import { NavLink } from 'react-router-dom';
//icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
//config
import config from '../../../config/config';
library.add(fas);
const SidebarLink = ({
  title, icon, newLink, route, onClick, iconType, isSubmenu
}) => (
  <NavLink
    to={route}
    onClick={onClick}
    activeClassName="sidebar__link-active"
    activeStyle={{ backgroundColor: config.darkTemplateColor, color: config.whiteColor }}
  >
    <row className="sidebar__link">
      {/* <span>
          <FontAwesomeIcon icon={["fa", 'user']} className="menu-icon" />
        </span> */}

      {(icon && (iconType === 'letters' || !iconType)) ?
        <span
          activeClassName={`active_sidebar__link-icon lnr `}
          className={`sidebar__link-icon lnr `} >{icon}</span>
        : ''}
      {(icon && iconType === 'icon') ?
        <span>
          <FontAwesomeIcon icon={["fa", icon]} className="menu-icon" />
        </span>
        : null}

      <p className="sidebar__link-title" activeClassName='sidebar__link-active'
        style={isSubmenu && isSubmenu == true ? { paddingLeft: "18px" } : { paddingLeft: "8px" }}
      >

        {title}
        {newLink ? <Badge className="sidebar__link-badge"><span>New</span></Badge> : ''}
      </p>
    </row>
  </NavLink>
);

SidebarLink.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.string,
  newLink: PropTypes.bool,
  route: PropTypes.string,
  onClick: PropTypes.func,
  iconType: PropTypes.string
};

SidebarLink.defaultProps = {
  icon: '',
  newLink: false,
  route: '/',
  onClick: () => { },
};

export default SidebarLink;